import React, { useEffect } from 'react';
import ReactBody from 'react-body';

export const Styleguide = (props) => {

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <section id="styleguide">
      <iframe id="designgapp" title="Styleguide" width="100%" height="768" frameBorder="0" src="https://www.designgapp.com/s/ck9XCTljN" onLoad={typeof window !== 'undefined' ? window.addEventListener('message', (e) => document.getElementById('designgapp').height = JSON.parse(e.data).height+'px') : null} allowFullScreen />
      <ReactBody className="styleguide" />
    </section>
  );
}

export default Styleguide;