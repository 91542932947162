import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

const config = {
  apiKey: "AIzaSyCAbixTp0X7BXbjUCKKl3P8gkQP9IXcQKw",
  authDomain: "native-technology-solutions.firebaseapp.com",
  databaseURL:
    "https://native-technology-solutions-default-rtdb.firebaseio.com",
  projectId: "native-technology-solutions",
  storageBucket: "native-technology-solutions.appspot.com",
  messagingSenderId: "903905408033",
  appId: "1:903905408033:web:8d9423f4f583f2d4319f60",
  measurementId: "G-2FS0W13K7N",
};

export const app = initializeApp(config);
export const adminApp = initializeApp(config, "secondary");
export const db = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const sendContactEmail = httpsCallable(functions, "sendContactEmail");
export const sendJobApplication = httpsCallable(
  functions,
  "sendJobApplication"
);
export const removeUserAuthentication = httpsCallable(
  functions,
  "removeUserAuthentication"
);
