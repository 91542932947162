import React, { Component } from 'react';
import { PasswordForgetLink } from './PasswordForget';

export default class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleSetState = obj => {
    this.setState({...obj});
  }

  render() {
    const {
      email,
      password,
      loggingIn,
      loggedIn,
      error
    } = this.state;

    const { onSubmit } = this.props;

    const isInvalid = email === '' || password === '';

    return (
      <form className="admin-form" onSubmit={(evt) => onSubmit(evt, this.state, this.handleSetState)}>
        <input autoFocus type="text" value={email} placeholder="* Email"
          onChange={event => this.setState({email: event.target.value})} />
        <input type="password" value={password} placeholder="* Password"
          onChange={event => this.setState({password: event.target.value})} />
        <button id="submit" className={`btn btn-quaternary ${loggedIn ? 'btn-success' : ''}`} type="submit" disabled={isInvalid}>
          { !loggingIn && !loggedIn ?
            <span>login</span>
            : !loggingIn && loggedIn ?
              <span><i className="icon icon-checkmark-circle" />logged in</span>
            : <span className="btn-loading" />
          }
        </button>
        <PasswordForgetLink />
        { error && <p className="error">{error.message}</p> }
      </form>
    );
  }
}

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  loggingIn: false,
  loggedIn: false
};
