import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';
import ContentEditor from '../content/ContentEditor';
import ContentDisplay from '../content/ContentDisplay';
import ReactBody from 'react-body';
import { Helmet } from 'react-helmet-async';

const Services = (props) => {

  const { authUser, siteContent } = props;

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  return (
    siteContent && siteContent.services ?
      <section id="services">
        <Helmet>
          <title>NTS - Services</title>
        </Helmet>
        <div className="services-wrap">
          <div className="service service-voltage" id="voltage">
            <div className="service-bg">
              <div className="content-wrap">
                { authUser ?
                  <ContentEditor
                    page="services"
                    item="voltage"
                    type="header" />
                  : <ContentDisplay
                      page="services"
                      item="voltage"
                      type="header" />
                }
              </div>
            </div>
            <div className="content-wrap">
              { authUser ?
                <ContentEditor
                  page="services"
                  item="voltage"
                  type="content" />
                : <ContentDisplay
                    page="services"
                    item="voltage"
                    type="content" />
              }
              <Link to={routes.Contact} className="btn btn-primary">
                Get Started
              </Link>
            </div>
          </div>
          <div className="service service-utility" id="utility">
            <div className="service-bg">
              <div className="content-wrap">
                { authUser ?
                  <ContentEditor
                    page="services"
                    item="utility"
                    type="header" />
                  : <ContentDisplay
                      page="services"
                      item="utility"
                      type="header" />
                }
              </div>
            </div>
            <div className="content-wrap">
              { authUser ?
                <ContentEditor
                  page="services"
                  item="utility"
                  type="content" />
                : <ContentDisplay
                    page="services"
                    item="utility"
                    type="content" />
              }
              <Link to={routes.Contact} className="btn btn-primary">
                Get Started
              </Link>
            </div>
          </div>
          <div className="service service-systems" id="systems">
            <div className="service-bg">
              <div className="content-wrap">
                { authUser ?
                  <ContentEditor
                    page="services"
                    item="systems"
                    type="header" />
                  : <ContentDisplay
                      page="services"
                      item="systems"
                      type="header" />
                }
              </div>
            </div>
            <div className="content-wrap">
              { authUser ?
                <ContentEditor
                  page="services"
                  item="systems"
                  type="content" />
                : <ContentDisplay
                    page="services"
                    item="systems"
                    type="content" />
              }
              <Link to={routes.Contact} className="btn btn-primary">
                Get Started
              </Link>
            </div>
          </div>
        </div>
        <ReactBody className="services" />
      </section>
    : <div className="content-intro">
        <div className="content-loading page-loading" />
      </div>
  )
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  siteContent: state.sessionState.siteContent
});

const mapDispatchToProps = (dispatch) => ({
  setSiteContent: (siteContent) => dispatch({ type: 'SITE_CONTENT', siteContent })
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);
