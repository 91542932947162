import React, { Component } from 'react';
import { db, firebase } from '../../firebase';
import { Confirm } from 'semantic-ui-react';

export default class AdminUsers extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const { authUser } = this.props;
    if (authUser) {
      db.getUsers().then(res => {
        this.setState({users: res.val()});
      });
    }
  }

  handleSetState = obj => {
    this.setState({...obj});
  };

  toggleRemoveUserConfirm = (uid) => {
    const { showDeleteUserConfirm } = this.state;
    this.setState({
      showDeleteUserConfirm: !showDeleteUserConfirm,
      userDeleteKey: uid,
    });
  };

  removeAdmin = () => {
    const uid = this.state.userDeleteKey;
    this.setState({ 
      deleting: true,
      showDeleteUserConfirm: false
    }, () => {
      firebase.removeUserAuthentication({ uid })
      .then(() => {
        db.deleteUser(uid)
        .then(() => {
          db.getUsers().then(res => {
            this.setState({
              users: res.val(),
              showDeleteUserConfirm: false,
              userDeleteKey: '',
              deleting: false
            });
          });
        })
        .catch(err => {
          console.log("ERR", err);
          this.showDeleteError();
        });
      })
      .catch(err => {
        console.log("ERR", err);
        this.showDeleteError();
      });
    });
  };

  showDeleteError = () => {
    this.setState({
      error: {message: 'Could not delete user at this time'},
      showDeleteUserConfirm: false,
      userDeleteKey: '',
      deleting: false
    }, () => {
      setTimeout(() => {
        this.setState({ error: null });
      }, 2000);
    });
  };

  createCurrentAdmins = () => {
    const { authUser } = this.props;
    return Object.keys(this.state.users).map(key => {
      const email = this.state.users[key].email;
      return (
        <div key={email} className="user-info admin-item">
          <span className="link" href={`mailto:${email}`} target="_blank">{email}</span>
          {key !== authUser.uid ?
            <div className="admin-remove item-remove" onClick={() => this.toggleRemoveUserConfirm(key)}>
              <span className="icon icon-close" />
            </div>
            : <span className="user-you">(you)</span>
          }
        </div>
      )
    })
  };

  render() {
    const { deleting, error, showDeleteUserConfirm } = this.state;
    const currentAdmins = this.createCurrentAdmins();

    return (
      <div className='current-admins-wrap'>
        <h2>Current administrators</h2>
        <div className="user-info-list admin-item-list">
          {currentAdmins}
          { deleting ?
            <div className="admin-delete">
              <h3>Deleting user...</h3>
              <div className="inline-loading" />
            </div>
            : null
          }
        </div>
        { error && <p className="error">{error.message}</p> }
        <Confirm
          className='confirm delete'
          open={showDeleteUserConfirm}
          header='Sure you want to delete this user?'
          content="The user will be permanently removed."
          cancelButton='nevermind'
          confirmButton='yes, delete'
          onCancel={() => this.toggleRemoveUserConfirm('')}
          onConfirm={this.removeAdmin} 
        />
      </div>
    );
  }
}

const INITIAL_STATE = {
  email: '',
  error: null,
  users: {},
  showDeleteUserConfirm: false,
  userDeleteKey: '',
  deleting: false
};
