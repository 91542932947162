import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as routes from "constants/routes";
import { db } from "../../firebase";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerShow: false,
      headerHide: false,
    };
  }

  componentDidMount() {
    if (window.innerWidth > 768) {
      window.addEventListener("scroll", this.handleScroll);
    }
    if (!this.props.siteContent) {
      this.getContent();
    }
  }

  getContent = () => {
    db.getContent().then((content) => {
      this.props.setSiteContent(content.val());
    });
  };

  oldScroll = 0;

  handleScroll = (event) => {
    let pageY = window.scrollY;
    if (
      pageY > 500 &&
      this.state.headerShow &&
      this.oldScroll < pageY &&
      !this.state.headerHide
    ) {
      this.setState({
        headerHide: true,
      });
    } else if (
      pageY > 500 &&
      !this.state.headerShow &&
      this.oldScroll > pageY &&
      this.state.headerHide
    ) {
      this.setState({
        headerHide: false,
      });
    }
    if (pageY > 1000 && !this.state.headerShow && this.oldScroll > pageY) {
      this.setState({
        headerShow: true,
      });
    } else if (
      (pageY < 500 && this.state.headerShow) ||
      this.oldScroll < pageY
    ) {
      this.setState({
        headerShow: false,
      });
    }
    if (pageY === 0) {
      this.setState({
        headerShow: false,
        headerHide: false,
      });
    }
    this.oldScroll = pageY;
  };

  render() {
    const { headerShow, headerHide } = this.state;

    return (
      <header
        className={`header-wrap ${headerShow ? "show" : ""} ${
          headerHide ? "hide" : ""
        }`}
      >
        <div className="logo">
          <Link to="/">
            <img
              src={
                require(typeof window !== "undefined" && window.innerWidth < 768
                  ? `../../images/nts-emblem-white.svg`
                  : `../../images/nts-logo-white.svg`).default
              }
              alt="Native Technology Solutions"
            />
          </Link>
        </div>
        <nav className="nav-header">
          <Link to={routes.Services} className="nav-item nav-item-services">
            Services
          </Link>
          <Link to={routes.About} className="nav-item nav-item-about">
            About
          </Link>
          <Link to={routes.Careers} className="nav-item nav-item-careers">
            Careers
          </Link>
          <Link to={routes.Contact} className="nav-item nav-item-contact">
            Contact
          </Link>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  siteContent: state.sessionState.siteContent,
});

const mapDispatchToProps = (dispatch) => ({
  setSiteContent: (siteContent) =>
    dispatch({ type: "SITE_CONTENT", siteContent }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
