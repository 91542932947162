import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import renderHTML from 'react-render-html';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { db, firebase } from '../../firebase';
import { Confirm, Menu, Dropdown } from 'semantic-ui-react';
import withAuthorization from 'components/withAuthorization';
import pretty from 'pretty';
import arrayMove from 'array-move';
import { modulesHeader, modulesContent, modulesContentSlim, formatsHeader, formatsContent, formatsContentSlim } from '../Functions';

class ContentEditor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: [],
      contentID: '',
      ...INITIAL_STATE
    };
  }

  componentDidMount() {
    const { siteContent, page, item, itemIndex, type } = this.props;
    this.setState({
      content: siteContent && siteContent[page] && siteContent[page][item] && siteContent[page][item][itemIndex] ? siteContent[page][item][itemIndex][type] : siteContent[page][item][type] ? siteContent[page][item][type] : [],
      contentID: siteContent && siteContent[page] && siteContent[page][item] && siteContent[page][item][itemIndex] ? siteContent[page][item][itemIndex].id : siteContent[page][item][type] ? siteContent[page][item].id : []
    });
  }

  componentDidUpdate(prevProps) {
    const { siteContent, page, item, itemIndex, type } = this.props;
    if (prevProps.siteContent[page] !== siteContent[page]) {
      this.setState({
        content: siteContent && siteContent[page] && siteContent[page][item] && siteContent[page][item][itemIndex] ? siteContent[page][item][itemIndex][type] : siteContent[page][item][type] ? siteContent[page][item][type] : [],
        contentID: siteContent && siteContent[page] && siteContent[page][item] && siteContent[page][item][itemIndex] ? siteContent[page][item][itemIndex].id : siteContent[page][item][type] ? siteContent[page][item].id : []
      });
    }
  }

  editOn = () => {
    if (!this.state.editing) {
      this.setState({
        editing: true
      });
    }
  };

  editOff = () => {
    if (this.state.editing) {
      this.setState({
        editing: false
      });
    }
  };

  openDeletePostConfirm = (id) => {
    this.setState({
      postDeleteConfirmOpen: true
    });
  };

  cancelDeletePost = () => {
    this.setState({
      postDeleteConfirmOpen: false
    });
  };

  saveContent = () => {
    const { page, item, itemIndex, type } = this.props;
    const { content, contentID } = this.state;

    this.setState({
      contentEditStatus: 'saving'
    }, () => {

    // This getContent lookup is not necessary but...
    // Just trying to make sure that all the other content is up-to-date so things don't get overwritten if two people are editing
      db.getContent().then((siteContent) => {
        const contentUpdate = siteContent.val();
        if (contentID) {
          db.getPostIndex(page, item, contentID).then((res) => {
            const postIndex = Object.keys(res.val())[0];
            contentUpdate[page][item][postIndex][type] = content;
            this.updateDB(`${item}/${postIndex}`, content, contentUpdate);
          });
        } else if (!contentID && itemIndex) {
          contentUpdate[page][item][itemIndex][type] = content;
          this.updateDB(`${item}/${itemIndex}`, content, contentUpdate);
        } else {
          contentUpdate[page][item][type] = content;
          this.updateDB(item, content, contentUpdate);
        }
      });
    });
  };

  updateDB = (path, content, update) => {
    const { page, type, setSiteContent } = this.props;
    db.saveContent(page, path, {[type]: pretty(content)}).then(() => {
      setSiteContent(update);
      this.setState({ ...INITIAL_STATE });
    });
  };

  deletePost = () => {
    const { page, setSiteContent } = this.props;
    const { contentID } = this.state;
    this.setState({
      contentEditStatus: 'deleting',
      postDeleteConfirmOpen: false
    }, () => {
      setTimeout(() => {
        db.getContent().then((siteContent) => {   
          const contentUpdate = siteContent.val();     
          db.getPostIndex(page, 'posts', contentID).then((res) => {       
            const postIndex = Object.keys(res.val())[0];
            const imageRef = contentUpdate[page].posts[postIndex].img;
            contentUpdate[page].posts.splice(postIndex, 1);
            db.savePosts(page, contentUpdate[page].posts).then(() => {
              setSiteContent(contentUpdate);
              if (imageRef && firebase.storage.refFromURL(imageRef)) {
                firebase.storage.refFromURL(imageRef).delete();
              }
              this.setState({
                contentEditStatus: ''
              });
            });
          });
        });
      }, 500);
    });
  };

  movePost = (postID, dir) => {
    const { page, setSiteContent } = this.props;
    const { contentID } = this.state;
    db.getContent().then((siteContent) => {
      const contentUpdate = siteContent.val();
      db.getPostIndex(page, 'posts', contentID).then((res) => {
        const postIndex = Object.keys(res.val())[0];
        if (dir === 'up') {
          contentUpdate[page].posts = arrayMove(contentUpdate[page].posts, postIndex, postIndex - 1)
        } else if (dir === 'down') {
          contentUpdate[page].posts = arrayMove(contentUpdate[page].posts, postIndex, postIndex + 1)
        } else if (dir === 'top') {
          contentUpdate[page].posts = arrayMove(contentUpdate[page].posts, postIndex, 0)
        } else if (dir === 'bottom') {
          contentUpdate[page].posts = arrayMove(contentUpdate[page].posts, postIndex, contentUpdate[page].posts.length - 1)
        }
        db.savePosts(page, contentUpdate[page].posts).then(() => {
          setSiteContent(contentUpdate);
        });
      });
    });
  };

  updateContent = (content, source) => {
    if (source === 'user') {
      let contentCopy = {...this.state.content};
      contentCopy = content;
      this.setState({
        content: contentCopy
      });
    }
  };

  render () {
    const { siteContent, page, itemIndex, item } = this.props;
    const { editing, content, contentEditStatus } = this.state;
    return (
      content && content.length > 0 ?
        <div className={`content-editor-wrap content-text ${contentEditStatus === 'deleting' ? 'deleting' : ''}`}>
          <span className={`content-editor ${editing ? 'active' : ''}`} onClick={this.editOn}>
            <span className="content-display">
              {renderHTML(content)}
            </span>
            <ReactQuill
              theme="snow"
              className="content-edit"
              value={content}
              modules={item === 'header' ? modulesHeader : item === 'posts' ?  modulesContent : item === 'services' ? modulesHeader : modulesContentSlim}
              formats={item === 'header' ? formatsHeader : item === 'posts' ? formatsContent : item === 'services' ? modulesHeader : formatsContentSlim}
              onChange={(content, delta, source, editor) => this.updateContent(content, source)} 
            />
            <button className="btn btn-success content-save" onClick={this.saveContent} disabled={contentEditStatus !== ''}>
              { contentEditStatus === 'saving' ?
                <span className="btn-loading" />
                : 'save'
              }
            </button>
          </span>
          { item.indexOf('post') !== -1 ?
            <span className="content-post-nav">
              <Menu className="dark">
                <Dropdown trigger={
                  <div className="trigger">
                    <i className="icon icon-ellipsis" />
                  </div>
                }>
                  <Dropdown.Menu>
                    { itemIndex > 0 ?
                      <span className="link menu-item" onClick={() => this.movePost(itemIndex, 'top')}><i className="icon icon-enter-up" /> move top</span>
                      : null
                    }
                    { itemIndex > 0 ?
                      <span className="link menu-item" onClick={() => this.movePost(itemIndex, 'up')}><i className="icon icon-arrow-up" /> move up</span>
                      : null
                    }
                    { itemIndex < siteContent[page].posts.length - 1 ?
                      <span className="link menu-item" onClick={() => this.movePost(itemIndex, 'down')}><i className="icon icon-arrow-down" /> move down</span>
                      : null
                    }
                    { itemIndex < siteContent[page].posts.length - 1 ?
                      <span className="link menu-item" onClick={() => this.movePost(itemIndex, 'bottom')}><i className="icon icon-enter-down" /> move bottom</span>
                      : null
                    }
                    <span className="link menu-item" onClick={() => this.openDeletePostConfirm(itemIndex)}><i className="icon icon-trash" /> delete</span>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>
              <Confirm
                className='confirm delete'
                open={this.state.postDeleteConfirmOpen}
                header='Sure you want to delete this job post?'
                content="The post will be permanently removed."
                cancelButton='nevermind'
                confirmButton='yes, delete'
                onCancel={this.cancelDeletePost}
                onConfirm={this.deletePost} 
              />
            </span>
            : null
          }
          <span className="content-loading" />
        </div>
      : null
    )
  }
}

const INITIAL_STATE = {
  editing: false,
  contentEditStatus: ''
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  siteContent: state.sessionState.siteContent
});

const mapDispatchToProps = (dispatch) => ({
  setSiteContent: (siteContent) => dispatch({ type: 'SITE_CONTENT', siteContent })
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps)
)(ContentEditor);
