import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import { connect } from 'react-redux';
import { compose } from 'recompose';

class ContentDisplay extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: [],
    };
  }

  componentDidMount() {
    const { siteContent, page, item, itemIndex, type } = this.props;
    this.setState({
      content: siteContent && siteContent[page] && siteContent[page][item] && siteContent[page][item][itemIndex] ? siteContent[page][item][itemIndex][type] : siteContent[page][item][type] ? siteContent[page][item][type] : []
    });
  }

  render () {
    const { content } = this.state;
    return (
      content && content.length > 0 ?
        <div className="content-text">
          {renderHTML(content)}
        </div>
      : null
    )
  }
}

const mapStateToProps = (state) => ({
  siteContent: state.sessionState.siteContent
});

export default compose(connect(mapStateToProps))(ContentDisplay);