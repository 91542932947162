import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';
import ContentEditor from '../content/ContentEditor';
import ContentDisplay from '../content/ContentDisplay';
import ReactBody from 'react-body';
import { Helmet } from 'react-helmet-async';

const About = (props) => {

  const { authUser, siteContent } = props;

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  return (
    siteContent && siteContent.about ?
      <section id="about">
        <Helmet>
          <title>NTS - About</title>
        </Helmet>
        <div className="hero">
          <div className="hero-bg" />

          { authUser ?
            <ContentEditor
              page="about"
              item="header"
              type="content" />
            : <ContentDisplay
                page="about"
                item="header"
                type="content" />
          }

          <Link to={routes.Services} className="btn btn-primary btn-text-white">
            Our Services
          </Link>
          <Link to={routes.Contact} className="btn btn-primary btn-text-white">
            Contact Us
          </Link>
        </div>
        <div className="about-wrap content-wrap">

          { authUser ?
            <ContentEditor
              page="about"
              item="body"
              type="content" />
            : <ContentDisplay
                page="about"
                item="body"
                type="content" />
          }

        </div>
        <ReactBody className="about" />
      </section>
    : <div className="content-intro">
        <div className="content-loading page-loading" />
      </div>
  )
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  siteContent: state.sessionState.siteContent
});

const mapDispatchToProps = (dispatch) => ({
  setSiteContent: (siteContent) => dispatch({ type: 'SITE_CONTENT', siteContent })
});

export default connect(mapStateToProps, mapDispatchToProps)(About);
