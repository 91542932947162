import { db } from "./firebase";
import {
  ref,
  get,
  query,
  set,
  remove,
  update,
  orderByChild,
  equalTo,
} from "firebase/database";

// User Functions

export const createUser = (id, email, role) =>
  set(ref(db, `users/${id}`), {
    email: email,
    lowercase_email: email.toLowerCase(),
    role: role,
  });

export const getUser = (userID) => get(ref(db, `users/${userID}`));

export const getUsers = () => get(ref(db, "users/"));

export const getInvites = () => get(ref(db, "invites/"));

export const lookupUserByEmail = (email) =>
  get(
    query(ref(db, "users/"), orderByChild("lowercase_email"), equalTo(email))
  );

// Content Functions

export const getContent = () => get(ref(db, "content/"));

export const saveContent = (page, item, content) =>
  update(ref(db, `content/${page}/${item}`), content);

export const saveCategories = (categories) =>
  set(ref(db, `content/blog/categories`), categories);

export const saveMapContent = (content) =>
  update(ref(db, `content/map/content`), content);

export const getPostIndex = (page, item, id) =>
  get(
    query(ref(db, `content/${page}/${item}`), orderByChild("id"), equalTo(id))
  );

export const saveCareerPost = (post) =>
  get(
    query(
      ref(db, "content/careers/posts"),
      orderByChild("id"),
      equalTo(post.id)
    )
  ).then((snapshot) => {
    if (snapshot.val()) {
      return update(
        ref(db, `content/careers/posts/${Object.keys(snapshot.val())[0]}`),
        post
      );
    } else {
      return get(ref(db, "content/careers/posts")).then((snapshot) => {
        return update(
          ref(
            db,
            `content/careers/posts/${
              snapshot.val() ? snapshot.val().length : 0
            }`
          ),
          post
        );
      });
    }
  });

export const savePosts = (page, posts) =>
  set(ref(db, `content/${page}/posts`), posts);

export const getSettings = () => get(ref(db, "settings/"));

export const updateEmailSettings = (careers, contact, leadGen) =>
  set(ref(db, "settings"), {
    emailCareers: careers,
    emailContact: contact,
  });

export const inviteNewUser = (id, email, callback) => {
  set(ref(db, `invites/${id}`), { email: email }).then(() => callback());
};

export const removeNewUserFromItemInviteTable = (userKey) =>
  set(ref(db, `invites/${userKey}`), []);

export const deleteUser = (userKey) => remove(ref(db, `users/${userKey}`));

export const deleteCategory = (catKey) =>
  remove(ref(db, `content/blog/categories/${catKey}`));

export const saveFiles = (files, type) =>
  set(ref(db, `content/${type}/files`), files);
