import React, { Component } from 'react';
import ReactBody from 'react-body';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { db, firebase } from '../../firebase'
import ContentEditor from '../content/ContentEditor';
import ContentDisplay from '../content/ContentDisplay';
import { Helmet } from 'react-helmet-async';
import { Modal, Confirm, Menu, Dropdown } from 'semantic-ui-react';
import renderHTML from 'react-render-html';
import pretty from 'pretty';
import ReactQuill from 'react-quill';
import shortid from 'shortid';
import arrayMove from 'array-move';
import Dropzone from 'react-dropzone';
import { convertFileSize, modulesContent, formatsContent } from '../Functions';

class Careers extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      careersPostModalOpen: false,
      applicationModalOpen: false,
      applicationJob: [],
      content: this.props.siteContent && this.props.siteContent.careers ? this.props.siteContent.careers : [],
      ...INITIAL_STATE 
    };
  }
  
  componentDidMount() {
    window.scrollTo(0,0);
  }

  componentDidUpdate() {
    if (this.props.siteContent && this.props.siteContent.careers && this.props.siteContent.careers !== this.state.content) {
      this.setState({
        content: this.props.siteContent.careers
      });
    }
  }

  toggleCareerPost = (id) => {
    const contentCopy = {...this.state.content};
    contentCopy.posts[id].active = contentCopy.posts[id].active === true ? [] : true;
    this.setState({
      content: contentCopy
    });
  };

  openCareersModal = (event) => {
    event.preventDefault();
    this.setState({ 
      careersPostModalOpen: true,
      postType: 'new',
      postID: shortid.generate()
    }, () => {
      document.getElementById('post-name').focus();
    });
  };

  closeCareersModal = () => {
    this.setState({ 
      careersPostModalOpen: false,
      ...INITIAL_STATE
    });
  };

  updateQuill = (item, content, delta, source, editor) => {
    if (source === 'user') {
      if (editor.getLength() > 1) {
        this.setState({[item]: content});
      } else {
        this.setState({[item]: ''});
      }
    }
  };

  updatePostInput = (item, content) => {
    this.setState({[item]: content});
  };

  focusQuill = (item) => {
    document.getElementById(`quill-${item}`).classList.add('focus');
  };

  blurQuill = (item) => {
    document.getElementById(`quill-${item}`).classList.remove('focus');
  };

  saveCareerPost = (event) => {
    event.preventDefault();
    const contentCopy = {...this.state.content};
    const {
      postID,
      postName,
      postSummary,
      postDescription,
      postType,
      postIndex
    } = this.state;

    this.setState({
      postingStatus: 'posting'
    }, () => {
      const postContent = {
        id: postID,
        name: postName && postName.length > 0 ? postName : null,
        summary: postSummary && postSummary.length > 0 ? pretty(postSummary) : null,
        description: postDescription && postDescription.length > 0 ? pretty(postDescription) : null
      };

      db.saveCareerPost(postContent).then(() => {
        setTimeout(() => {
          this.setState({ 
            postingStatus: 'posted'
          }, () => {
            setTimeout(() => {
              if (!contentCopy.posts) {
                contentCopy.posts = [];
              }
              contentCopy.posts[postType === 'edit' ? postIndex : contentCopy.posts.length] = postContent;
              this.setState({
                content: contentCopy
              }, () => {
                this.closeCareersModal();
                if (postType === 'new') {
                  const newPost = document.getElementById(`careers-item-${postID}`);
                  if (newPost) newPost.scrollIntoView('slow');
                }
                this.setState({ ...INITIAL_STATE });
              });
            }, 1000);
          });
        }, 750);
      });
    });
  };

  movePost = (postID, dir) => {
    db.getContent().then((siteContent) => {
      const contentUpdate = siteContent.val();
      db.getPostIndex('careers', 'posts', postID).then((res) => {
        const postIndex = Object.keys(res.val())[0];
        if (dir === 'up') {
          contentUpdate.careers.posts = arrayMove(contentUpdate.careers.posts, postIndex, postIndex - 1)
        } else if (dir === 'down') {
          contentUpdate.careers.posts = arrayMove(contentUpdate.careers.posts, postIndex, postIndex + 1)
        } else if (dir === 'top') {
          contentUpdate.careers.posts = arrayMove(contentUpdate.careers.posts, postIndex, 0)
        } else if (dir === 'bottom') {
          contentUpdate.careers.posts = arrayMove(contentUpdate.careers.posts, postIndex, contentUpdate.careers.posts.length - 1)
        }
        db.savePosts('careers', contentUpdate.careers.posts).then(() => {
          this.props.setSiteContent(contentUpdate);
          this.setState({  ...INITIAL_STATE });
        });
      });
    });
  };

  editCareerPost = (post, index) => {
    this.setState({
      postID: post.id,
      postName: post.name,
      postSummary: post.summary, 
      postDescription: post.description,
      careersPostModalOpen: true,
      postType: 'edit',
      postIndex: index
    });
  };

  deleteCareerPost = () => {
    const { postToDelete } = this.state;
    this.setState({
      postingStatus: 'deleting',
      postDeleteConfirmOpen: false
    }, () => {
      setTimeout(() => {
        db.getContent().then((siteContent) => {
          const contentUpdate = siteContent.val();
          db.getPostIndex('careers', 'posts', postToDelete.id).then((res) => {
            contentUpdate.careers.posts.splice(Object.keys(res.val())[0], 1);
            db.savePosts('careers', contentUpdate.careers.posts).then(() => {
              this.props.setSiteContent(contentUpdate);
              this.setState({  ...INITIAL_STATE });
            });
          });
        });
      }, 500);
    });
  };

  openDeletePostConfirm = (id, post) => {
    this.setState({
      postToDeleteID: id,
      postToDelete: post,
      postDeleteConfirmOpen: true
    });
  };

  cancelDeletePost = () => {
    this.setState({
      postToDeleteID: '',
      postToDelete: '',
      postDeleteConfirmOpen: false
    });
  };

  openApplicationModal = (event, job) => {
    event.preventDefault();
    this.setState({
      applicationJob: job,
      applicationModalOpen: true
    });
  };

  closeApplicationModal = () => {
    this.setState({
      applicationJob: [],
      applicationModalOpen: false
    });
  };
  
  render() {

    const { 
      content, 
      postName,
      postSummary,
      postDescription,
      postingStatus,
      careersPostModalOpen,
      postType,
      postToDeleteID,
      error,
      applicationJob
    } = this.state;

    const { authUser, siteContent } = this.props;

    const isInvalid = postName === '' || postSummary === '' || postDescription === '';

    return (
      siteContent && siteContent.careers ?
        <section id="careers" className={authUser ? 'editing' : ''}>
          <Helmet>
            <title>{'NTS - Careers'}</title>
          </Helmet>
          <div className="hero">
            <div className="hero-bg" />

            { authUser ?
              <ContentEditor
                page="careers"
                item="header"
                type="content" />
              : <ContentDisplay
                  page="careers"
                  item="header"
                  type="content" />
            }

          </div>
          <div className="careers-content">
            <div className="content-wrap careers-wrap">
      
              { content && content.posts && content.posts.length > 0 ?
                <div className={`careers-list ${postingStatus === 'loading' ? 'loading' : ''}`}>
                  { content.posts.map((post, index) =>
                      <div id={`careers-item-${post.id}`} className={`careers-item ${post.active === true ? 'active' : ''} ${postToDeleteID === index && postingStatus === 'deleting' ? 'deleting' : ''}`} key={post.id}>
                        { post.name ?
                          <h2>
                            {post.name}
                            { authUser ?
                              <Menu className="dark">
                                <Dropdown trigger={
                                  <div className="trigger">
                                    <i className="icon icon-ellipsis" />
                                  </div>
                                }>
                                  <Dropdown.Menu>
                                    <span className="link menu-item" onClick={() => this.editCareerPost(post, index)}><i className="icon icon-edit" /> edit post</span>
                                    { index > 0 ?
                                      <span className="link menu-item" onClick={() => this.movePost(post.id, 'top')}><i className="icon icon-arrow-outline-up" /> move top</span>
                                      : null
                                    }
                                    { index > 0 ?
                                      <span className="link menu-item" onClick={() => this.movePost(post.id, 'up')}><i className="icon icon-arrow-thick-up" /> move up</span>
                                      : null
                                    }
                                    { index < content.posts.length - 1 ?
                                      <span className="link menu-item" onClick={() => this.movePost(post.id, 'down')}><i className="icon icon-arrow-thick-down" /> move down</span>
                                      : null
                                    }
                                    { index < content.posts.length - 1 ?
                                      <span className="link menu-item" onClick={() => this.movePost(post.id, 'bottom')}><i className="icon icon-arrow-outline-down" /> move bottom</span>
                                      : null
                                    }
                                    <span className="link menu-item" onClick={() => this.openDeletePostConfirm(index, post)}><i className="icon icon-trash" /> delete</span>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Menu>
                              : null
                            }
                          </h2>
                          : null
                        }
                        { post.summary ?
                          <div className="summary">
                            {renderHTML(post.summary)}
                          </div>
                          : null
                        }
                        <div className="careers-item-detail">
                          { post.description ?
                            <div className="description">
                              <h3>Full Description</h3>
                              {renderHTML(post.description)}
                            </div>
                            : null
                          }
                        </div>
                        { Object.keys(post).length > 3 ?
                          <button className={`btn btn-primary btn-link ${postingStatus === 'posting' ? 'posting' : ''}`} onClick={() => this.toggleCareerPost(index)}>
                            { post.active === true ? <span><i className="icon icon-minus-outline" />read less</span> : <span><i className="icon icon-add-outline" />read more</span> }
                          </button>
                          : null
                        }
                        <div className="careers-item-btns">
                          <button className="btn btn-secondary" onClick={(event) => this.openApplicationModal(event, post)}>
                            apply now
                          </button>
                        </div>
                      </div>
                    )
                  }
                </div>
                : (content && !content.posts) || content.posts.length === 0 ?
                  <div className="zero-state">
                    <i className="icon icon-inbox" />
                    <h2>There are currently no job postings</h2>
                  </div>
                : <div className="content-loading no-header" />
              }

              { authUser ?
                <div className="careers-post-wrap">
                  
                  <button className="btn btn-quaternary" onClick={this.openCareersModal}>
                    post new job
                  </button>

                  <CareerPostEdit
                    careersPostModalOpen={careersPostModalOpen} 
                    closeCareersModal={this.closeCareersModal} 
                    saveCareerPost={this.saveCareerPost}
                    focusQuill={this.focusQuill}
                    blurQuill={this.blurQuill}
                    updateQuill={this.updateQuill}
                    updatePostInput={this.updatePostInput}
                    postName={postName}
                    postSummary={postSummary}
                    postDescription={postDescription}
                    isInvalid={isInvalid}
                    postingStatus={postingStatus}
                    postType={postType}
                    error={error}
                  />
                  <Confirm
                    className='confirm delete'
                    open={this.state.postDeleteConfirmOpen}
                    header='Sure you want to delete this job post?'
                    content="The post will be permanently removed."
                    cancelButton='nevermind'
                    confirmButton='yes, delete'
                    onCancel={this.cancelDeletePost}
                    onConfirm={this.deleteCareerPost} 
                  />
                </div>
                : null
              }
              
            </div>
            <Modal
              open={this.state.applicationModalOpen}
              onClose={this.closeApplicationModal}
              className="modal-application-form">
              <span className="modal-close" onClick={this.closeApplicationModal}>
                <i className="icon icon-close" />
              </span>
              <Modal.Header>
                <h1>{applicationJob.name}</h1>
              </Modal.Header>
              <Modal.Content>
                <CareerApplication close={this.closeApplicationModal} job={applicationJob} />
              </Modal.Content>
          </Modal>
          </div>

          <ReactBody className="careers" />
        </section>
        : <div className="content-intro">
            <div className="content-loading page-loading" />
          </div>
    );
  }
}

const CareerPostEdit = ({ careersPostModalOpen, closeCareersModal, saveCareerPost, focusQuill, blurQuill, updateQuill, updatePostInput, postName, postSummary, postDescription, isInvalid, postingStatus, postType, error }) =>
  <Modal
    open={careersPostModalOpen}
    onClose={closeCareersModal}
    closeOnDimmerClick={false}
    className="modal-post-career">
    <span className="modal-close" onClick={closeCareersModal}>
      <i className="icon icon-close" />
    </span>
    <Modal.Header>
      <h1>{postType === 'new' ? 'Post New Job' : 'Edit Job Post'}</h1>
    </Modal.Header>
    <Modal.Content>
      <div className="careers-post">
        <form id="career-form" onSubmit={saveCareerPost}>
          <div className={`form-row row-name ${postName && postName.length > 0 ? 'complete' : ''}`}>
            <label className="required">Job Name</label>
            <input 
              id="post-name"
              type="text" 
              value={postName} 
              placeholder="Enter job name" 
              onChange={event => updatePostInput('postName', event.target.value)} />
          </div>
          <div className={`form-row row-summary ${postSummary && postSummary.length > 1 ? 'complete' : ''}`}>
            <label className="required">Job Summary</label>
            
            <ReactQuill  
              id="quill-postSummary"
              theme="snow" 
              value={postSummary || ''}
              modules={modulesContent}
              formats={formatsContent}
              placeholder="Enter job summary" 
              onFocus={() => focusQuill('postSummary')}
              onBlur={() => blurQuill('postSummary')}
              onChange={(content, delta, source, editor) => updateQuill('postSummary', content, delta, source, editor)} 
            />

          </div>
          <div className={`form-row row-description ${postDescription && postDescription.length > 0 ? 'complete' : ''}`}>
            
            <label className="required">Job Description</label>
            <ReactQuill  
              id="quill-postDescription"
              theme="snow" 
              value={postDescription || ''}
              modules={modulesContent}
              formats={formatsContent}
              placeholder="Enter job summary" 
              onFocus={() => focusQuill('postDescription')}
              onBlur={() => blurQuill('postDescription')}
              onChange={(content, delta, source, editor) => updateQuill('postDescription', content, delta, source, editor)} 
            />

          </div>

          <button className="btn btn-quaternary" type="submit" disabled={isInvalid || postingStatus !== ''}>
            { postingStatus === 'posting' ?
                <span className="btn-loading" />
              : postingStatus === 'posted' ?
                <span className="btn-complete">
                  <i className="icon icon-check" />
                  { postType === 'new' ? 'posted!' : 'updated!' }
                </span>
              : postType === 'new' ? 'post new job' : 'update job'
            }
          </button>
          
          { error && <p className="error">{error.message}</p> }

        </form>
      </div>
    </Modal.Content>
  </Modal>

const INITIAL_STATE = {
  postID: '',
  postName: '',
  postSummary: '',
  postDescription: '',
  postingStatus: '',
  postType: '',
  postToDeleteID: '',
  postToDelete: '',
  postIndex: '',
  error: [],
  postDeleteConfirmOpen: false
};

class CareerApplication extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE_APP };
  }

  componentDidMount() {
    db.getSettings().then(res => {
      this.setState({
        emailCareers: res.val().emailCareers ? res.val().emailCareers : ''
      });
    });
  }
  
  submitApplication = (event) => {
    event.preventDefault();
    if (!this.state.applicationSending) {
      const { resumeFile, applicationFile } = this.state;
      const { job } = this.props;
      this.setState({
        applicationSending: true
      }, () => {
        const application = applicationFile[0];
        const applicationReader = new FileReader();
        const resume = resumeFile[0];
        const resumeReader = new FileReader();
        if (application && resume) {
          applicationReader.onload = (event) => {
            const applicationContent = event.target.result;
            resumeReader.onload = (event) => {
              const resumeContent = event.target.result;
              firebase.sendJobApplication({
                to: this.state.emailCareers,
                jobID: job.id,
                jobName: job.name, 
                applicationFileName: application.name,
                applicationFileContent: applicationContent,
                resumeFileName: resume.name,
                resumeFileContent: resumeContent
              }).then(() => {
                this.setState({
                  resumeFile: '',
                  applicationFile: '',
                  error: null,
                  resumeUploaded: false,
                  applicationUploaded: false,
                  applicationSending: false,
                  applicationSent: true
                });
              });
            };
            resumeReader.readAsDataURL(resume);
          };
          applicationReader.readAsDataURL(application);
        } else if (application && !resume) {
          applicationReader.onload = (event) => {
            const applicationContent = event.target.result;
            firebase.sendJobApplication({
              to: this.state.emailCareers,
              jobID: job.id,
              jobName: job.name, 
              applicationFileName: application.name,
              applicationFileContent: applicationContent,
              resumeFileName: null,
              resumeFileContent: null
            }).then(() => {
              this.setState({
                resumeFile: '',
                applicationFile: '',
                error: null,
                resumeUploaded: false,
                applicationUploaded: false,
                applicationSending: false,
                applicationSent: true
              });
            });
          };
          applicationReader.readAsDataURL(application);
        } else if (!application && resume) {
          resumeReader.onload = (event) => {
            const resumeContent = event.target.result;
            firebase.sendJobApplication({
              to: this.state.emailCareers,
              jobID: job.id,
              jobName: job.name, 
              applicationFileName: null,
              applicationFileContent: null,
              resumeFileName: resume.name,
              resumeFileContent: resumeContent
            }).then(() => {
              this.setState({
                resumeFile: '',
                applicationFile: '',
                error: null,
                resumeUploaded: false,
                applicationUploaded: false,
                applicationSending: false,
                applicationSent: true
              });
            });
          };
          resumeReader.readAsDataURL(resume);
        } else {
          console.log('ERROR -- NO FILES!');
        }
      });
    }
  };

  setApplicationFile = (file) => {
    this.setState({
      applicationFile: file,
      applicationUploaded: true
    });
  };

  setResumeFile = (file) => {
    this.setState({
      resumeFile: file,
      resumeUploaded: true
    });
  };

  removeApplicationFile = (event) => {
    event.preventDefault();
    this.setState({
      applicationFile: '',
      applicationUploaded: false
    });
  };

  removeResumeFile = (event) => {
    event.preventDefault();
    this.setState({
      resumeFile: '',
      resumeUploaded: false
    });
  };

  render() {
    const {
      emailCareers,
      resumeFile,
      resumeUploaded,
      applicationFile,
      applicationUploaded,
      applicationSending,
      applicationSent,
      error
    } = this.state;

    const isInvalid = resumeFile === '' && applicationFile === '';

    return (
      <div id="application-form" className={`application-wrap ${applicationSent ? 'submitted' : ''}`}>
        <h3>Please download the application, fill it out, and upload it along with your résumé.</h3>
        <a className="btn btn-quaternary application-btn" href="https://www.native-tech.net/docs/NTS-Application.pdf" target="_blank" rel="noopener noreferrer" download>
          download application
        </a>

        <Dropzone 
          style={{}}
          multiple={false}
          disabled={applicationUploaded || applicationSent}
          className={`application-dropzone files-dropzone ${applicationUploaded ? 'uploaded' : ''}`}
          activeClassName="active"
          onDrop={(file) => this.setApplicationFile(file)}>
          <div className="application-files-drop files-drop">
            <h4>Drop your completed application file here or...</h4>
            <div className="btn btn-primary btn-text-white btn-small">click to upload</div>
            <span className="files-drop-msg">
              <i className="icon icon-dropzone" />
              <p>Drop file here</p>
            </span>
            <span className="files-drop-progress">
              <span id="application-drop-progress" className="drop-progress-bar" />
              <span className="upload-loading" />
            </span>
            { applicationFile && applicationFile[0] ?
              <span className="file-content">
                <p>{applicationFile[0].name} &ndash; <span>{convertFileSize(applicationFile[0].size)}</span></p>
                <button className="btn btn-text" onClick={this.removeApplicationFile}>remove file</button>
              </span>
              : null
            }
          </div>
        </Dropzone>

        <Dropzone 
          style={{}}
          multiple={false}
          disabled={resumeUploaded || applicationSent}
          className={`resume-dropzone files-dropzone ${resumeUploaded ? 'uploaded' : ''}`}
          activeClassName="active"
          onDrop={(file) => this.setResumeFile(file)}>
          <div className="application-files-drop files-drop">
            <h4>Drop your resume file here or...</h4>
            <div className="btn btn-primary btn-text-white btn-small">click to upload</div>
            <span className="files-drop-msg">
              <i className="icon icon-dropzone" />
              <p>Drop file here</p>
            </span>
            <span className="files-drop-progress">
              <span id="application-drop-progress" className="drop-progress-bar" />
              <span className="upload-loading" />
            </span>
            { resumeFile && resumeFile[0] ?
              <span className="file-content">
                <p>{resumeFile[0].name} &ndash; <span>{convertFileSize(resumeFile[0].size)}</span></p>
                <button className="btn btn-text" onClick={this.removeResumeFile}>remove file</button>
              </span>
              : null
            }
          </div>
        </Dropzone>

        { emailCareers ?
          <button className={`btn btn-quaternary btn-submit ${applicationSent ? 'btn-success' : ''}`} onClick={this.submitApplication} disabled={isInvalid || applicationSending}>
            { !applicationSending && !applicationSent ?
              <span>apply now</span>
              : !applicationSending && applicationSent ?
                <span><i className="icon icon-checkmark-circle" /> Application Submitted!</span>
              : <span className="btn-loading" />
            }
          </button>
          : <div className="inline-loading" />
        }

        { error && <p className="error">{error.message}</p> }

      </div>
    );
  }
}

const INITIAL_STATE_APP = {
  resumeFile: '',
  applicationFile: '',
  resumeUploaded: false,
  applicationUploaded: false,
  applicationSending: false,
  applicationSent: false,
  emailCareers: '',
  error: 'Something broke'
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  siteContent: state.sessionState.siteContent
});

const mapDispatchToProps = (dispatch) => ({
  setSiteContent: (siteContent) => dispatch({ type: 'SITE_CONTENT', siteContent })
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Careers);