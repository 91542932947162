import React, { Component } from 'react';
import { db } from '../../firebase';
import ReactBody from 'react-body';
import { onSignUp } from 'utils/auth';
// import AdminLogin from './AdminLogin';
import * as routes from "constants/routes";

export default class AdminSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const ID = this.props.match && this.props.match.params ? this.props.match.params.id : false;
    if (!ID) {
      this.props.history.push(routes.Home);
    } else {
      db.getInvites().then(res => {
        const invites = res.val();
        const idArray = Object.keys(invites);
        if (!idArray.includes(ID)) {
          this.props.history.push(routes.Home);
        } else {
          this.setState({ 
            invites,
            email: invites[ID].email ? invites[ID].email : ''
          });
        }
      });
    }
  }

  handleSetState = obj => {
    this.setState({...obj});
  }

  goToAdmin = () => {
    this.props.history.push(routes.Admin)
  }

  signUp = (evt) => {
    // TODO: delete email from invite table - done
    // TODO: Remove users
    // TODO: Move signup into a function
    // TODO: return feedback that invite has been sent - done
    // TODO: handle errors - done
    // TODO: move send email function to watch for db change on invites table - done
    evt.preventDefault();
    const {
      email,
      invites
    } = this.state;

    this.setState({
      signUpStatus: 'loading'
    }, () => {
      const emails = Object.keys(invites).map(key => invites[key].email);
      const ID = this.props.match.params.id;
      if (emails.includes(email)) {
        this.setState({ error: null }, () => {
          onSignUp(this.state, ID, this.handleSetState, this.goToAdmin);
        });
      } else {
        this.setState({ 
          error: { message: "This email has not been invited to join NTS" },
          signUpStatus: ''
        });
      }
    });
  }

  render() {
    const {
      email,
      password,
      password2,
      error,
      signUpStatus
    } = this.state;

    const isInvalid = email === '' || password === '' || password2 !== password;

    return (
      <section id="admin" onLoad={ window.scrollTo(0,0) }>
        <div className="content-intro">
          <div className="content-wrap">
            <div className="admin-container-wrap">
              <h2>Admin Sign Up</h2>
              { email && email !== '' ?
                <form className="admin-form" onSubmit={this.signUp}>
                  <input type="text" value={email} placeholder="* Email" disabled
                    onChange={event => this.setState({email: event.target.value})} />
                  <input type="password" value={password} placeholder="* Password"
                    onChange={event => this.setState({password: event.target.value})} />
                  <input type="password" value={password2} placeholder="* Re-enter Password"
                    onChange={event => this.setState({password2: event.target.value})} />
                  <button id="submit" className="btn btn-quaternary" type="submit" disabled={isInvalid || signUpStatus !== ''}>
                    { signUpStatus === 'loading' ?
                      <span className="btn-loading" />
                      : <span>sign up</span>
                    }
                  </button>
                  { error && <p className="error">{error.message}</p> }
                </form>
                : <div className="content-loading" />
              }
            </div>
          </div>
        </div>
        <ReactBody className="contact" />
      </section>
    );
  }
}

const INITIAL_STATE = {
  email: '',
  password: '',
  password2: '',
  signUpStatus: '',
  invites: {},
  error: null
};
