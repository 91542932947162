import React, { Component } from 'react';
import ReactBody from 'react-body';
import { connect } from 'react-redux';
import { firebase } from '../../firebase';
import ContentEditor from '../content/ContentEditor';
import ContentDisplay from '../content/ContentDisplay';
import { Helmet } from 'react-helmet-async';
import { db } from '../../firebase';

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
const demoFancyMapStyles = require("../../data/mapStyles.json");
const MapWithAMarker = withScriptjs(withGoogleMap(props =>
  <GoogleMap
    defaultZoom={10}
    defaultCenter={{ lat: 33.377730, lng: -111.962070 }}
    defaultOptions={{
      scrollwheel: false,
      styles: demoFancyMapStyles
    }}
  >
    <Marker
      position={{ lat: 33.299930, lng: -111.942070 }}
    />
  </GoogleMap>
));

export class ContactForm extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      ...INITIAL_STATE,
      focus: props.focus
    };
  }

  componentDidMount() {
    db.getSettings().then(res => {
      this.setState({
        emailContact: res.val().emailContact ? res.val().emailContact : ''
      });
    });
  }
  
  onSubmit = (event) => {
    event.preventDefault();
    const { emailContact, name, email, message, company, phone } = this.state;
    this.setState({
      emailSending: true
    }, () => {
      firebase.sendContactEmail({ to: emailContact, name: name, email: email, company: company, phone: phone, message: message }).then(() => {
        this.setState({
          name: '',
          email: '',
          message: '',
          company: '',
          phone: '',
          error: null,
          emailSending: false,
          emailSent: true
        }, () => {
          setTimeout(() => {
            this.setState({
              emailSent: false
            });
          }, 4000);
        });
      });
    });
  };

  render() {
    const {
      name,
      email,
      message,
      company,
      phone,
      error,
      emailSending,
      emailSent,
      focus
    } = this.state;

    const isInvalid =
      name === '' ||
      email === '' ||
      message === '';

    return (
      <form className="contact-form" onSubmit={this.onSubmit}>
        <input type="text" value={name} placeholder="* Name" autoFocus={focus ? true : false}
          onChange={event => this.setState({name: event.target.value})} />
        <input type="email" value={email} placeholder="* Email"
          onChange={event => this.setState({email: event.target.value})} />
        <input className="col-2" type="text" value={company} placeholder="Company"
          onChange={event => this.setState({company: event.target.value})} />
        <input className="col-2" type="tel" value={phone} placeholder="Phone"
          onChange={event => this.setState({phone: event.target.value})} />
        <textarea value={message} placeholder="* How can we help you?" rows="4"
          onChange={event => this.setState({message: event.target.value})} />
        <button id="submit" className={`btn ${emailSent ? 'btn-success' : ''}`} type="submit" disabled={isInvalid}>
          { !emailSending && !emailSent ?
            <span>send message</span>
            : !emailSending && emailSent ?
              <span><i className="icon icon-checkmark-circle" /> message sent!</span>
            : <span className="btn-loading" />
          }
        </button>
        { error && <p className="error">{error.message}</p> }
      </form>
    );
  }
}

class Contact extends Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {

    const { authUser, siteContent } = this.props;

    return (
      siteContent && siteContent.contact ?
        <section id="contact">
          <Helmet>
            <title>{'NTS Contact'}</title>
          </Helmet>
          <div className="contact-map">
            <MapWithAMarker
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyATk7pN2KozRH5VgEP_Lw4ZUC3uN4jO9oE&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `36em` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
          <div className="content-wrap">
            <div className="contact-text">
              { authUser ?
                <ContentEditor
                  page="contact"
                  item="header"
                  type="content" />
                : <ContentDisplay
                    page="contact"
                    item="header"
                    type="content" />
              }
            </div>
            <div className="contact-form-wrap">
              <h2>Contact Us</h2>
              <ContactForm focus={true} />
            </div>
          </div>
          <ReactBody className="contact" />
        </section>
        : <div className="content-intro">
            <div className="content-loading page-loading" />
          </div>
    )
  }
}

const INITIAL_STATE = {
  name: '',
  email: '',
  message: '',
  category: '',
  company: '',
  phone: '',
  error: null,
  emailSending: false,
  emailSent: false,
  emailContact: ''
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  siteContent: state.sessionState.siteContent
});

export default connect(mapStateToProps)(Contact);