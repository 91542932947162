import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "components/core/Header";
import Footer from "components/core/Footer";
import Index from "components/core/Index";
import Home from "components/core/Home";
import Services from "components/core/Services";
import About from "components/core/About";
import Contact from "components/core/Contact";
import Careers from "components/core/Careers";
import Styleguide from "components/core/Styleguide";
import Admin from "components/admin/Admin";
import AdminSignUp from "components/admin/AdminSignUp";
import PasswordForget from "components/admin/PasswordForget";
import PasswordChange from "components/admin/PasswordChange";
import PageNotFound from "components/core/PageNotFound";
import * as routes from "constants/routes";
import withAuthentication from "./withAuthentication";
// import { RefreshToast } from './registerServiceWorker';
// import ReactGA from 'react-ga';
// import TagManager from 'react-gtm-module';

const App = () => (
  <Router>
    <div className="app-wrap">
      <Header />
      <Switch>
        <Route exact path={routes.Index} component={Index} />
        <Route exact path={routes.Home} component={Home} />
        <Route exact path={routes.Services} component={Services} />
        <Route exact path={routes.About} component={About} />
        <Route exact path={routes.Contact} component={Contact} />
        <Route exact path={routes.Careers} component={Careers} />
        <Route exact path={routes.Styleguide} component={Styleguide} />
        <Route exact path={routes.Admin} component={Admin} />
        <Route path={`${routes.AdminSignUp}/:id`} component={AdminSignUp} />
        <Route exact path={routes.PasswordForget} component={PasswordForget} />
        <Route exact path={routes.PasswordChange} component={PasswordChange} />
        <Route exact path="/404" component={PageNotFound} />
        <Route path="*" component={PageNotFound} />
      </Switch>
      <Footer />
      {
        //<RefreshToast />
      }
    </div>
  </Router>
);

// ReactGA.initialize('UA-127515180-1');
// ReactGA.pageview(window.location.pathname + window.location.search);
// TagManager.initialize({ gtmId: 'GTM-TKRG5KG' });

export default withAuthentication(App);
