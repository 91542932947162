import React, { useEffect } from "react";
import { connect } from "react-redux";
import ReactBody from "react-body";
import { onLogin } from "utils/auth";
import AdminControl from "./AdminControl";
import AdminLogin from "./AdminLogin";
import AdminUsers from "./AdminUsers";

const Admin = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section id="admin">
      <div className="content-intro">
        <div className="content-wrap">
          {!props.authUser ? (
            <div className="admin-container-wrap">
              <h2>Login</h2>
              <AdminLogin onSubmit={onLogin} {...props} />
            </div>
          ) : (
            <div className="admin-container-wrap">
              <div className="admin-wrap">
                <AdminUsers {...props} />
                <AdminControl {...props} />
              </div>
            </div>
          )}
        </div>
      </div>
      <ReactBody className="admin" />
    </section>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  siteContent: state.sessionState.siteContent,
});

const mapDispatchToProps = (dispatch) => ({
  setSiteContent: (siteContent) =>
    dispatch({ type: "SITE_CONTENT", siteContent }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
