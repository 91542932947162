import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import * as routes from "constants/routes";
import { onLogOut } from "utils/auth";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  openContactModal = (event) => {
    event.preventDefault();
    this.setState({ contactModalOpen: true });
  };

  closeContactModal = () => {
    this.setState({ contactModalOpen: false });
  };

  render() {
    const { authUser, history } = this.props;

    return (
      <footer>
        <div className="logo">
          <Link to={routes.Home}>
            <img
              src={require("../../images/nts-emblem.svg").default}
              alt="Native Technology Solutions"
            />
          </Link>
        </div>
        <nav className="nav-footer">
          <Link to={routes.Services}>Services</Link>
          <Link to={routes.About}>About</Link>
          <Link to={routes.Careers}>Careers</Link>
          <Link to={routes.Contact}>Contact</Link>
          {authUser ? <Link to={routes.Admin}>Admin</Link> : null}
          {authUser ? (
            <span className="link" onClick={() => onLogOut(history)}>
              Logout
            </span>
          ) : null}
        </nav>
        <div className="social-footer">
          <a
            href="https://www.linkedin.com/company/native-technology-solutions-inc-"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i alt="Connect on LinkedIn" className="icon icon-linkedin" />
          </a>
          <a
            href="https://twitter.com/NativeTechAZ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i alt="Connect on Twitter" className="icon icon-twitter" />
          </a>
          <a
            href="https://www.facebook.com/NativeTechAZ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i alt="Connect on Facebook" className="icon icon-facebook" />
          </a>
        </div>
      </footer>
    );
  }
}

const INITIAL_STATE = {
  contactModalOpen: false,
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default compose(withRouter, connect(mapStateToProps))(Footer);
