import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import * as routes from "constants/routes";
import ContentEditor from "../content/ContentEditor";
import ContentDisplay from "../content/ContentDisplay";
import { ContactForm } from "./Contact";
import ReactBody from "react-body";
import { Helmet } from "react-helmet-async";
import renderHTML from "react-render-html";
import earth from "videos/earth.mp4";

const Home = (props) => {
  const { authUser, siteContent } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const videoRef = useRef();
  const maxCount = 250;
  const shortenText = (text, maxLength) => {
    return maxLength > 0 && text.length > maxLength
      ? text.slice(0, maxLength) + "..."
      : text;
  };

  return siteContent && siteContent.home ? (
    <section id="home">
      <Helmet>
        <title>NTS - Home</title>
      </Helmet>
      <div className="hero">
        {typeof window !== "undefined" && window.innerWidth > 768 ? (
          <div className="hero-video">
            <video
              autoPlay
              muted
              loop
              ref={videoRef}
              onCanPlay={() => (videoRef.current.playbackRate = 0.67)}
            >
              <source src={earth} type="video/mp4" />
            </video>
          </div>
        ) : (
          <div className="hero-img">
            <img
              src={require("../../images/hero-home.jpg").default}
              alt="Native Technolgy Solutions"
            />
          </div>
        )}
        {authUser ? (
          <ContentEditor page="home" item="header" type="content" />
        ) : (
          <ContentDisplay page="home" item="header" type="content" />
        )}
        <Link to={routes.Services} className="btn btn-primary btn-text-white">
          Our Services
        </Link>
      </div>
      <div className="services-wrap">
        <div className="service service-voltage">
          <img
            src={require("../../images/icon-wire.svg").default}
            alt="Voltage Solutions"
          />
          {authUser ? (
            <ContentEditor page="home" item="services" type="voltage" />
          ) : (
            <ContentDisplay page="home" item="services" type="voltage" />
          )}
          <Link to={`${routes.Services}#voltage`} className="btn btn-primary">
            Voltage Solutions
          </Link>
        </div>
        <div className="service service-utility">
          <img
            src={require("../../images/icon-drill.svg").default}
            alt="Utility Solutions"
          />
          {authUser ? (
            <ContentEditor page="home" item="services" type="utility" />
          ) : (
            <ContentDisplay page="home" item="services" type="utility" />
          )}
          <Link to={`${routes.Services}#utility`} className="btn btn-primary">
            Utility Solutions
          </Link>
        </div>
        <div className="service service-systems">
          <img
            src={require("../../images/icon-cloud.svg").default}
            alt="Systems Solutions"
          />
          {authUser ? (
            <ContentEditor page="home" item="services" type="systems" />
          ) : (
            <ContentDisplay page="home" item="services" type="systems" />
          )}
          <Link to={`${routes.Services}#systems`} className="btn btn-primary">
            Systems Solutions
          </Link>
        </div>
      </div>
      <div className="hero hero-sub">
        <div className="hero-bg" />
        {authUser ? (
          <ContentEditor page="home" item="mission" type="content" />
        ) : (
          <ContentDisplay page="home" item="mission" type="content" />
        )}
        <Link to={routes.Services} className="btn btn-primary btn-text-white">
          Our Services
        </Link>
      </div>
      <div className="home-bottom">
        <div className="home-contact">
          <h3>Contact us</h3>
          <ContactForm />
        </div>
        <div className="home-careers">
          <h3>NTS Careers</h3>
          {siteContent &&
          siteContent.careers &&
          siteContent.careers.posts &&
          siteContent.careers.posts.length > 0 ? (
            <div className="careers-list">
              {siteContent.careers.posts.slice(0, 2).map((post, index) => (
                <Link
                  to={`${routes.Careers}#careers-item-${post.id}`}
                  key={post.id}
                >
                  <div id={`careers-item-${index}`} className="careers-item">
                    <h2>{post.name}</h2>
                    <div className="summary">
                      {renderHTML(shortenText(post.summary, maxCount))}
                    </div>
                  </div>
                </Link>
              ))}
              <Link to={routes.Careers} className="btn btn-secondary">
                view all
              </Link>
            </div>
          ) : !siteContent.careers ||
            !siteContent.careers.posts ||
            siteContent.careers.posts.length === 0 ? (
            <div className="zero-state">
              <i className="icon icon-inbox" />
              <h2>There are currently no job postings</h2>
            </div>
          ) : (
            <div className="content-loading no-header" />
          )}
        </div>
      </div>
      <ReactBody className="home" />
    </section>
  ) : (
    <div className="content-intro">
      <div className="content-loading page-loading" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  siteContent: state.sessionState.siteContent,
});

const mapDispatchToProps = (dispatch) => ({
  setSiteContent: (siteContent) =>
    dispatch({ type: "SITE_CONTENT", siteContent }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
