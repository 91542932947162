export const Index = '/index';
export const Home = '/';
export const Services = '/services';
export const About = '/about';
export const Styleguide = '/styleguide';
export const Contact = '/contact';
export const Careers = '/careers';
export const Admin = '/admin';
export const AddUser = '/add-admin';
export const AdminSignUp = '/admin-signup';
export const PasswordForget = '/pass-forget';
export const PasswordChange = '/pass-change';