import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { auth } from '../../firebase';
import ReactBody from 'react-body';
import withAuthorization from 'components/withAuthorization';

class PasswordChangeForm extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { authUser } = this.props;
    const { passwordCurrent, passwordOne } = this.state;

    this.setState({
      changeProcessing: true
    }, () => {
      auth.doSignInWithEmailAndPassword(authUser.email, passwordCurrent)
        .then(() => {
          auth.doPasswordUpdate(passwordOne)
          .then(() => {
            this.setState({
              passwordCurrent: '',
              passwordOne: '',
              passwordTwo: '',
              error: null,
              changeSuccess: true,
              changeProcessing: false
            }, () => {
              if (this.props.passwordChangeSuccess) {
                this.props.passwordChangeSuccess();
              }
            });
          })
          .catch(error => {
            console.log(error);
            this.setState({ 
              error: error,
              changeProcessing: false 
            });
          });
        })
        .catch(error => {
          console.log(error);
          this.setState({ 
            error: error,
            changeProcessing: false
          });
        });
    });
  };

  render() {
    const {
      passwordCurrent,
      passwordOne,
      passwordTwo,
      error,
      changeSuccess,
      changeProcessing
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      passwordCurrent === '';

    return (
      <div className="password-change">
        { !changeSuccess ? 
          <form className="admin-form" id="form-pass" onSubmit={this.onSubmit}>
            <input
              autoFocus
              value={passwordCurrent}
              onChange={event => this.setState({passwordCurrent: event.target.value})}
              type="password"
              placeholder="Current Password"
            />
            <input
              value={passwordOne}
              onChange={event => this.setState({passwordOne: event.target.value})}
              type="password"
              placeholder="New Password"
            />
            <input
              value={passwordTwo}
              onChange={event => this.setState({passwordTwo: event.target.value})}
              type="password"
              placeholder="Confirm New Password"
            />
            <button className="btn btn-primary" disabled={isInvalid} type="submit">
              { !changeProcessing ?
                <span>change password</span>
                : <span className="btn-loading" /> 
              }
            </button>
            { error && <p className="error">{error.message}</p> }
          </form>
          : <div className="password-change-success auth-success">
              <i className="icon icon-checkmark-circle" />
              <h1>Your password was changed!</h1>
            </div>
        }
      </div>
    );
  }
}

const INITIAL_STATE = {
  passwordCurrent: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  changeSuccess: false,
  changeProcessing: false
};

const PasswordChangePage = ({ history, authUser }) =>
  <section id="pass-change" className="content-outer" onLoad={ window.scrollTo(0,0) }>
    <h1>Change Password</h1>
    <PasswordChangeForm history={ history} authUser={authUser} />
    <Link className="auth-bottom-link" to="/account">my account</Link>
    <ReactBody className="pass-change" />
  </section>

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps)
)(PasswordChangePage);

export {
  PasswordChangeForm
}
