import React, { useState, useEffect } from 'react';
import { inviteAdmin } from 'utils/auth';
import shortid from 'shortid';
import { db } from '../../firebase';

export const AdminControl = (props) => {

  const [email, setEmail] = useState('');
  const [emailCareers, setEmailCareers] = useState('');
  const [emailContact, setEmailContact] = useState('');
  const [emailStatus, setEmailStatus] = useState('');
  const [newUserId, setNewUserId] = useState(shortid.generate());
  const [response, setResponse] = useState(null);

  const isInvalid = email === '';
  const isInvalidEmail = emailCareers === '' || emailContact === '';

  useEffect(() => {
    db.getSettings().then(res => {
      setEmailCareers(res.val().emailCareers ? res.val().emailCareers : '');
      setEmailContact(res.val().emailContact ? res.val().emailContact : '');
    });
  }, []);

  const handleInvitedUser = (obj) => {
    setResponse({ message: "Email sent!" });
    setNewUserId(shortid.generate());
    setEmail('');
  };

  const updateEmailSettings = (event) => {
    event.preventDefault();
    setEmailStatus('updating');
    db.updateEmailSettings(emailCareers, emailContact).then(() => {
      setEmailStatus('updated');
      setTimeout(() => {
        setEmailStatus('');
      }, 3000);
    });
  };

  return (
    <div className="admin-controls">
      <div className="admin-add admin-sub-setting">
        <h2>Add new admins</h2>
        <form className="admin-form" onSubmit={(evt) => inviteAdmin(evt, email, newUserId, handleInvitedUser)}>
          <input type="text" value={email} placeholder="* Email"
            onChange={(event) => setEmail(event.target.value)} />
            { response && <p className="response">{response.message}</p> }
          <button id="add-admin-submit" className='btn btn-primary btn-small' type="submit" disabled={isInvalid}>
            add admin
          </button>
        </form>
      </div>
      <div className="admin-email-settings admin-setting">
        <h2>Email settings</h2>
        <form className="admin-form" onSubmit={(evt) => updateEmailSettings(evt)}>
          <label>Careers email</label>
          <input type="text" value={emailCareers} placeholder="* Careers email"
            onChange={event => setEmailCareers(event.target.value)} />
          <label>Contact email</label>
          <input type="text" value={emailContact} placeholder="* Contact email"
            onChange={event => setEmailContact(event.target.value)} />
          <button id="email-settings-submit" className='btn btn-primary btn-small' type="submit" disabled={isInvalidEmail || emailStatus !== ''}>
            { emailStatus === 'updating' ?
                <span className="btn-loading" />
              : emailStatus === 'updated' ?
                'settings updated!'
              : 'update settings'
            }
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdminControl;