const INITIAL_STATE = {
  authUser: null,
  userImg: "",
  userName: "",
  userSignupState: false,
  userLoginState: false,
  siteContent: null,
};

const setAuthUser = (state, action) => ({
  ...state,
  authUser: action.authUser,
});

const changeAuthUserImage = (state, action) => ({
  ...state,
  userImg: action.img,
});

const changeAuthUserName = (state, action) => ({
  ...state,
  userName: action.name,
});

const changeUserSignupState = (state, action) => ({
  ...state,
  userSignupState: action.status,
});

const changeUserLoginState = (state, action) => ({
  ...state,
  userLoginState: action.status,
});

const setSiteContent = (state, action) => ({
  ...state,
  siteContent: action.siteContent,
});

function sessionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "AUTH_USER": {
      return setAuthUser(state, action);
    }
    case "AUTH_USER_IMAGE": {
      return changeAuthUserImage(state, action);
    }
    case "AUTH_USER_NAME": {
      return changeAuthUserName(state, action);
    }
    case "USER_SIGNUP_STATE": {
      return changeUserSignupState(state, action);
    }
    case "USER_LOGIN_STATE": {
      return changeUserLoginState(state, action);
    }
    case "SITE_CONTENT": {
      return setSiteContent(state, action);
    }
    default:
      return state;
  }
}

export default sessionReducer;
